var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"50%"}},[_c('validation-observer',{ref:"userValidation"},[_c('div',{staticClass:"d-flex justify-center mb-6"},[_c('h3',[_vm._v("Confirm Student Information")])]),_c('validation-provider',{attrs:{"name":"dob","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"large":"","outlined":"","rounded":"","type":"date","error-messages":errors,"label":"Confirm Student Birthdate","placeholder":"Confirm Birth Date here..."},on:{"input":function($event){return _vm.$emit('getData', _vm.userData)}},model:{value:(_vm.userData.birth_date),callback:function ($$v) {_vm.$set(_vm.userData, "birth_date", $$v)},expression:"userData.birth_date"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Student First Name","outlined":"","rounded":""},on:{"input":function($event){return _vm.$emit('getData', _vm.userData)}},model:{value:(_vm.userData.first_name),callback:function ($$v) {_vm.$set(_vm.userData, "first_name", $$v)},expression:"userData.first_name"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Student Last Name","outlined":"","rounded":""},on:{"input":function($event){return _vm.$emit('getData', _vm.userData)}},model:{value:(_vm.userData.last_name),callback:function ($$v) {_vm.$set(_vm.userData, "last_name", $$v)},expression:"userData.last_name"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"readonly":"","label":"Student Email","outlined":"","rounded":""},on:{"input":function($event){return _vm.$emit('getData', _vm.userData)}},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-autocomplete',{staticStyle:{"margin-bottom":"25px"},attrs:{"error-message":errors,"is-rounded":"","placeholder":"Search Home Address","label":"Student Address","type":"street"},on:{"val":_vm.setStreetValue},model:{value:(_vm.userData.street_address),callback:function ($$v) {_vm.$set(_vm.userData, "street_address", $$v)},expression:"userData.street_address"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required"}},[_c('ATeleInput',{staticClass:"mb-6",attrs:{"input-text":"black","label":"Student Mobile Phone Number","disabled":"","is-rounded":true},on:{"get":_vm.getValidation},model:{value:(_vm.userData.phone),callback:function ($$v) {_vm.$set(_vm.userData, "phone", $$v)},expression:"userData.phone"}})],1),(_vm.isAdult && _vm.isPaid)?_c('validation-provider',{attrs:{"slim":"","rules":"required|min:11|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-center mb-6"},[_c('h3',[_vm._v("Enter W9 Tax Information")])]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###-##-####'),expression:"'###-##-####'"}],staticClass:"mt-2",attrs:{"error-messages":errors,"label":"Student Social Security Number","outlined":"","rounded":""},on:{"input":function($event){return _vm.$emit('getData', _vm.userData)}},model:{value:(_vm.userData.social_security_number),callback:function ($$v) {_vm.$set(_vm.userData, "social_security_number", $$v)},expression:"userData.social_security_number"}})]}}],null,false,2632950609)}):_vm._e(),(_vm.isAdult)?_c('v-checkbox',{staticClass:"signup__conditions",attrs:{"single-line":"","outlined":"","full-width":""},on:{"input":function($event){return _vm.$emit('getData', _vm.userData)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{},[_vm._v("I agree to the above language & accept the offer")])]},proxy:true}],null,false,1017565320),model:{value:(_vm.agreeTerms),callback:function ($$v) {_vm.agreeTerms=$$v},expression:"agreeTerms"}}):_vm._e(),(_vm.isAdult)?_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"disabled":!_vm.agreeTerms,"error-messages":errors,"label":"Sign electronically by typing your full name","outlined":"","rounded":""},on:{"input":function($event){return _vm.$emit('getData', _vm.userData)}},model:{value:(_vm.userData.signature),callback:function ($$v) {_vm.$set(_vm.userData, "signature", $$v)},expression:"userData.signature"}})]}}],null,false,1812213030)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }