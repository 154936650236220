

























































































































import { defineComponent, computed, ref, watchEffect, watch } from '@vue/composition-api';
import ATeleInput from '@/components/atoms/ATeleInput.vue';
import AAutocomplete from '@/components/atoms/AAutocomplete.vue';
import { useUserState, useDbGetters, useUserActions } from '@/store';
import { ObjectId } from 'bson';

export default defineComponent({
  name: 'AgreementAdk',

  components: {
    ATeleInput,
    AAutocomplete
  },

  props: {
    isAdult: {
      type: Boolean,
      default: false
    },
    studentDoc: {
      type: Object,
      default: () => {}
    }
  },
  setup(props: any, ctx: any) {
    const { collection } = useDbGetters(['collection']);
    const { getDocumentByIdAndType } = useUserActions(['getDocumentByIdAndType']);
    const { user }: any = useUserState(['user']);
    const date = ref('');
    const formattedNumber = ref('');
    const isPhoneValid = ref(false);
    const agreeTerms = ref(false);
    const userValidation: any = ref('');
    const isUserInvalid = ref(false);
    const homeAddress = ref('');

    const userData: any = ref({
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      relationship: '',
      birth_date: '',
      social_security_number: '',
      street_address: ''
    });

    const isPaid = computed(() => {
      let compensation;
      if (!props.isAdult) {
        compensation = props.studentDoc?.data?.offerDetails?.compensation?.substring(0, 6);
      } else {
        compensation = props.studentDoc?.data?.offerDetails?.compensation?.substring(0, 6);
      }

      if (compensation === 'Unpaid') return false;
      return true;
    });

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted && val.valid) {
        formattedNumber.value = `+${val.countryCallingCode}${val.formatted}`;
      } else {
        formattedNumber.value = '';
      }
      isPhoneValid.value = val.valid;
      ctx.emit('getData', userData.value);
    }

    const isPhoneCheck = computed(() => {
      const substring = '+1';
      const substring1 = '+';

      if (userData.value.phone.includes(substring) || userData.value.phone.includes(substring1)) {
        return false;
      }

      return true;
    });

    watchEffect(() => {
      if (userValidation.value) {
        isUserInvalid.value = userValidation.value?.flags?.invalid;
        ctx.emit('validateUser', isUserInvalid.value);
      }
      if (isPhoneValid.value && isPhoneCheck.value) {
        ctx.emit('validatePhone', true);
      }
    });

    async function loadInitialData() {
      userData.value = {
        email: user.value?.email,
        first_name: user.value?.firstName,
        last_name: user.value?.lastName,
        phone: user.value?.phoneNumber.substring(2),
        birth_date: date.value,
        street_address: homeAddress.value
      };
    }

    async function loadStudent() {
      const student = await getDocumentByIdAndType({
        document_type: 'Student',
        document_id: ctx.root.$route.params.studentId,
        public_id: ctx.root.$route.params.studentId
      });
      // await collection.value!('Student').findOne({
      //   _id: new ObjectId(ctx.root.$route.params.studentId)
      // });
      const dataUser = await getDocumentByIdAndType({
        document_type: 'User',
        document_id: student.participant_id,
        public_id: student?._id
      });
      // await collection.value!('User').findOne({
      //   _id: new ObjectId(student.participant_id)
      // });
      const portfolio = await getDocumentByIdAndType({
        document_type: 'StudentPortfolio',
        document_id: student.participant_id,
        public_id: student?._id
      });
      // await collection.value!('StudentPortfolio').findOne({
      //   _id: new ObjectId(student.participant_id)
      // });

      userData.value = {
        email: dataUser.email,
        first_name: dataUser.firstName,
        last_name: dataUser.lastName,
        phone: dataUser.phoneNumber.substring(2),
        birth_date: portfolio.date,
        street_address: portfolio?.home?.streetAddress
      };
    }

    if (ctx.root.$route.params.programId || props.studentDoc?.data?.program_id) {
      getDocumentByIdAndType({
        document_type: 'StudentPortfolio',
        document_id: user.value?._id,
        public_id: props?.studentDoc?.data?._id
      }).then(res => {
        if (res) {
          date.value = res.date;
          homeAddress.value = res?.home?.streetAddress;
          loadInitialData();
        }
      });
    }

    watch(collection, val => {
      if (val) {
        loadStudent();
      }
    });

    if (ctx.root.$route.params.studentId) {
      loadStudent();
    }

    watch(agreeTerms, val => {
      ctx.emit('agreeTerms', val);
    });

    function setStreetValue(e) {
      if (e.target) {
        userData.value.street_address = e.target.value;
        ctx.emit('getData', userData.value);
      } else if (e.name) {
        userData.value.street_address = e.formatted_address;
        ctx.emit('getData', userData.value);
      }
    }

    return {
      setStreetValue,
      isPhoneValid,
      loadInitialData,
      isPhoneCheck,
      userData,
      getValidation,
      agreeTerms,
      userValidation,
      isUserInvalid,
      isPaid,
      homeAddress
    };
  }
});
